import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DatePicker, Modal, Space, Switch, Table, Tag } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Radio, Card, Popconfirm, Row, Col, Typography } from 'antd';

import {
    ReloadOutlined,
    SearchOutlined,
    DownloadOutlined,

    ClearOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { downloadInventoryFile, downloadReport, productDatetimeWiseCountReport } from './slice-ticketInventory';
import moment from 'moment';
import { MasterSelect } from '../common/MasterSelect';
import { Field } from 'rc-field-form';
import InputNumber from 'rc-input-number';
import { COLOR_CODE_VALUE } from './TicketInventoryProduct';
import { ModalDownladTickets } from './TIPDownloadModal';
import { useNavigate } from 'react-router-dom';





const getUniqueKey = (el:any) => el.key + el.date + el.time;



const PopoverForm = forwardRef(({ downLoadBucket, el, setDownloadBucket, ticketCategory, disabled }: any, ref) => {
  const [st, setS] = useState<any>()
  const [form] = Form.useForm();


  useEffect(() => {
    form.setFieldValue('ticketCategory', ticketCategory);
    setS({});
  }, [ticketCategory])


  let avalAdult = 0;
  let avalChild = 0;


  downLoadBucket.data.forEach((ele: any) => {
    if (getUniqueKey(el) === getUniqueKey(ele)) {
      if (ele.type === 'adult') {
        avalAdult += ele.count;
      } else {
        avalChild += ele.count;
      }
    }
  });


  const getavialticket = () => {
    return form.getFieldValue('type') === 'adult' ?
      (form.getFieldValue('ticketCategory') === 'pdf' ? el.total_avail_adult - avalAdult : el.total_avail_code_adult - avalAdult)
      : (form.getFieldValue('ticketCategory') === 'pdf' ? el.total_avail_child - avalChild : el.total_avail_code_child - avalChild)
  }

 
  const onConfirm = () => {
    form
      .validateFields()
      .then((e) => {
        setDownloadBucket({
          ...downLoadBucket, popover: 0, data: [...downLoadBucket.data,
          {
            date: el.date,
            time: el.time,
            key: el.key,
            type: form.getFieldValue('type'),
            count: form.getFieldValue('count'),
            ticketCategory: form.getFieldValue('ticketCategory'),
            sellingprice: form.getFieldValue('sellingprice'),
          }
          ]
        })
        form.resetFields();
      })
      .catch((e) => console.log(e));

  }

  const onCancel = () => {
    form.resetFields();
    setDownloadBucket({ ...downLoadBucket, popover: 0 })
  }


  useImperativeHandle(ref, () => ({
    onCancel, onConfirm
  }));

  return  <div style={{width: '250px'}}><Form form={form} onValuesChange={e => setS(e)} initialValues={{ type: 'adult', ticketCategory: ticketCategory }} layout="vertical" requiredMark>
    <Form.Item
      name="ticketCategory"
      label="Select Ticket Category"

      rules={[
        {
          required: true,
          message: "Select Ticket Category",
        },
      ]}
    >
      <Radio.Group disabled={ticketCategory} onChange={(e) => {
        form.resetFields(['type', 'count']);
      }}>
        <Radio value="pdf">Pdf</Radio>
        <Radio value="mediacode"> Media Code </Radio>
      </Radio.Group>
    </Form.Item>
    <Form.Item label="Select Ticket type" name={"type"} rules={[{
      required: true,
      message: 'Select ticket type'
    }]}>
      <Radio.Group  onChange={()=>
        form.resetFields(['count'])
      }>
        <Radio.Button value="adult">Adult</Radio.Button>
        <Radio.Button value="child">Child</Radio.Button>
      </Radio.Group>
    </Form.Item>
    <Form.Item label="Ticket Count" name="count" rules={[{
      required: true,
      message: 'Enter Ticket Count'
    }, {
      type: 'number',
      min: 1,
      message: 'Ticket count should be greater than 0'
    }, { type: 'number', max: getavialticket(), message: `Count should not be more than available ticket (${getavialticket()})` }]}>
      <InputNumber placeholder='Enter Ticket Count' />
    </Form.Item>
    <Form.Item label="Selling price per ticket" name="sellingprice" rules={[{
      required: true,
      message: 'Enter Ticket selling price'
    }, { type: 'number', min: 1, message: 'Value should be more than 0' }]}>
      <InputNumber placeholder='Enter Ticket selling price' />
    </Form.Item>
  </Form>
    </div>
});

const DownloadButton = ({downLoadBucket, el,setDownloadBucket, ticketCategory, disabled} : any) => {

  const formref = useRef();
  const nav = useNavigate();

   return  <Popconfirm
  title="Title"
  description={
    
    downLoadBucket.popover === getUniqueKey(el) ? <PopoverForm disabled={disabled}
      downLoadBucket={downLoadBucket}
      ref={formref}
      ticketCategory={ticketCategory }
      el={el}
      setDownloadBucket={setDownloadBucket} /> : null
  }
  
     onConfirm={() => (formref.current as any)?.onConfirm()}
   okText="Add to download"
//   okButtonProps={{ loading: confirmLoading }}
     onCancel={() => (formref.current as any)?.onCancel()}

     open={downLoadBucket.popover === getUniqueKey(el)}
>
  {}
  <Button 
   disabled={disabled}
    onClick={() => {
      setDownloadBucket({ ...downLoadBucket, popover: getUniqueKey(el)})
    }
} > <DownloadOutlined /> Download </Button> {" "}
<Button 
  disabled={!el.key}
   onClick={() => {
    nav(`/home/ticketinventory/list?status=All&pnr=${el.key}`)
    }
} > Action </Button>



</Popconfirm>
}


export const TicketListForProduct = (props: any) => {
  const { productdatetimewisedata, productdatetimewiseloading, filterData, invenotryProductPopupStates } = useSelector((state: any) => state.ticketInventory);
    const dispatch = useDispatch()
    const [downLoadBucket, setDownloadBucket] = useState<any>({popover: false, data: []});
    const [inputValue, setInputValue] = useState<string>('');
    const loadData = () => {
        dispatch(productDatetimeWiseCountReport({
            id: invenotryProductPopupStates.statement?.id,
            pnr: filterData.pnr,
            date: filterData.date,
        }))
    }
    useEffect(() => {loadData()}, [])
 

    return (<> 
    {downLoadBucket.downloadPopup? 
    <ModalDownladTickets open={downLoadBucket.downloadPopup} 
    bucketData={downLoadBucket.data} 
          
    onClose={() => setDownloadBucket({...downLoadBucket, downloadPopup: false}) }/>
    : null }
                <Row>
      <Col span={downLoadBucket.data.length>0?14:24}>
                <Table
                loading={productdatetimewiseloading}
                  dataSource={productdatetimewisedata.filter((el:any)=>inputValue === '' || el.key.toLowerCase().startsWith(inputValue?.toLowerCase()))}
                >
                    <Table.Column title={<>
                    PNR 
                    <Input placeholder='print' 
                    value={inputValue} 
                    onChange={(e:any)=>setInputValue(e.target.value)} />
                    </>}
                     dataIndex="key"  />
                    <Table.Column title="Date" dataIndex="date"  />
                    <Table.Column title="Time" dataIndex="time"  />
                    
        
        <Table.ColumnGroup title="Available Pdf Count">
        <Table.Column title="Adult" render={(_: any, record: any) => (
          <Typography.Text type={record.total_avail_adult < COLOR_CODE_VALUE.MIN_TICKET_AVAIL_VALUE ? 'danger' : (record.total_avail_adult > COLOR_CODE_VALUE.MAX_TICKET_AVAIL_VALUE ? 'success' : undefined)}>{record.total_avail_adult}</Typography.Text>
        )} />
        <Table.Column title="Child" render={(_: any, record: any) => (
          <Typography.Text type={record.total_avail_child < COLOR_CODE_VALUE.MIN_TICKET_AVAIL_VALUE ? 'danger' : (record.total_avail_child > COLOR_CODE_VALUE.MAX_TICKET_AVAIL_VALUE ? 'success' : undefined)}>{record.total_avail_child}</ Typography.Text>
        )} />
        </Table.ColumnGroup>
      <Table.ColumnGroup title="Available Code Count">
        <Table.Column title="Adult" render={(_: any, record: any) => (
          <Typography.Text type={record.total_avail_code_adult < COLOR_CODE_VALUE.MIN_TICKET_AVAIL_VALUE ? 'danger' : (record.total_avail_code_adult > COLOR_CODE_VALUE.MAX_TICKET_AVAIL_VALUE ? 'success' : undefined)}>{record.total_avail_code_adult}</Typography.Text>
        )} />
        <Table.Column title="Child" render={(_: any, record: any) => (
          <Typography.Text type={record.total_avail_code_child < COLOR_CODE_VALUE.MIN_TICKET_AVAIL_VALUE ? 'danger' : (record.total_avail_code_child > COLOR_CODE_VALUE.MAX_TICKET_AVAIL_VALUE ? 'success' : undefined)}>{record.total_avail_code_child}</ Typography.Text>
        )} />
      </Table.ColumnGroup>
                    <Table.Column title="Action" render={
(el: any) => <DownloadButton disabled={!(el.totalaval>0)} 
downLoadBucket={downLoadBucket} 
ticketCategory={downLoadBucket.data.length > 0 ? downLoadBucket.data[0].ticketCategory : undefined}
el={el} 
setDownloadBucket={setDownloadBucket} />
}  />
                </Table>
      </Col>
      {downLoadBucket.data.length>0? <>
      
      <Col span={10} style={{background: '#eaeaea'}}>
        <Card 
        title={<>
        <h2>Download</h2>
         <Button onClick={()=>setDownloadBucket({...downLoadBucket, data: []})}>
            <ClearOutlined />
             Clear All </Button>
         {" "}
         <Button type='primary' onClick={e=>{
          setDownloadBucket({...downLoadBucket, downloadPopup: invenotryProductPopupStates.statement})
         }}>  <DownloadOutlined /> Create Download Request</Button>
        </>}
        
        >
          <div className='dataTableMobileView'>
            <Table dataSource={downLoadBucket.data}>
                <Table.Column title="PNR" dataIndex="key"  />
                <Table.Column title="Date" dataIndex="date"  />
                <Table.Column title="Time" dataIndex="time"  />
                <Table.Column title="Type" dataIndex="type"  />
                <Table.Column title="count" dataIndex="count"  />
                <Table.Column title="Selling Price per ticket" dataIndex="sellingprice"  />
                <Table.Column title="Action" render={(el, i)=>{
                    return <ClearOutlined  onClick={()=>{
                        setDownloadBucket({...downLoadBucket, data: downLoadBucket.data.filter((eee:any)=>eee!==el)})
                    }}/>
                }}  />
            </Table>
          </div>
        </Card>
      </Col>
      </>
      : null}
    </Row>
                
        </>);
}





