import React, { useEffect, useRef, useState } from 'react';
import { DatePicker, Drawer, Modal, Space, Spin, Switch, Table, Tag } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Select, Card } from 'antd';
import {
    ReloadOutlined,
    SearchOutlined,
    DownloadOutlined,LoadingOutlined,
    CheckOutlined,
    CloseOutlined,
    ClearOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { MasterSelect } from '../common/MasterSelect';
import {
    SyncOutlined
  } from '@ant-design/icons';
import { DataTable } from '../common/DataTable';
import { useNavigate } from 'react-router-dom';
import { createDraftQuotationFromData, itineraryPreview, itinerarysendQuotationToEmail, resetquota, updateDraftQuotation } from './slice-itineraryquotation'
import { popMessage } from '../../App';
import { formatDateInTimeZone } from '../utils/CommonFn';


export const QuotationList = () => {

  const nav = useNavigate();
const dispatch = useDispatch();
const datatableref = useRef();
const {hoteldata, quotationemailsetnloading, quotation}= useSelector((state:any)=>state.itineraryquotation)

  const [uistate, setuiState] = useState({showQutotaions: 0, email: undefined});

    return (<>
       {uistate.showQutotaions> 0 ? <Drawer
    placement={'right'}
    open={uistate.showQutotaions > 0} 
    onClose={() => setuiState({...uistate, showQutotaions: 0}) } 
    width={'calc(100% - 100px)'}
    title={
     <>
     {/* <Button style={{float: 'right', marginRight: 20}}  onClick={()=>{}}><ReloadOutlined /> Refresh </Button> */}
     </> 
    }
    destroyOnClose>
        
        <DataTable
            title="Quotations" 
            ref={datatableref}
            key="id"  
            componentuniquekey={`quotationlistbycustomerid${uistate.showQutotaions}`}
            lazyPagination={false}
            buildApiUrl={(pageNumber, pagesize)=>`itinerary/fetchQuotation?customer_id=${uistate.showQutotaions}&pageNo=${pageNumber}&pageSize=${pagesize}`} >
              <Table.Column title="Tour Count" render={(el:any)=> `${+el.adultCount + +el.childCount}` }/>
              <Table.Column dataIndex="adultCount" title="Adult Count"/>
              <Table.Column dataIndex="childCount" title="Child Count"/>
              <Table.Column dataIndex="fromDate" title="From Date" render={(el: any) => formatDateInTimeZone(el) }/>
              <Table.Column dataIndex="toDate" title="To Date" render={(el: any) => formatDateInTimeZone(el) }/>
              <Table.Column dataIndex="created" title="Created" render={(el: any) => formatDateInTimeZone(el) }/>
              
              <Table.Column  title="Email Sent" render={(el: any) => el.isEmailSent? <CheckOutlined />: <CloseOutlined />}/>
              <Table.Column dataIndex="userName" title="Qutotaion Generated By"/>
              <Table.Column title="Qutotaions" render={(el: any) => <a onClick={()=>{
                console.log(el, JSON.parse(el.quotationDetails))
                dispatch(itineraryPreview(el.id)).then((e:any)=>{
                //  console.log(e.payload.data)
                  const wi =  window.open('', "_blank");
                  let html = e.payload.data
                  html  = html.replaceAll(`http://localhost:${process.env.REACT_APP_BACKEND_PORT}/`, `${process.env.REACT_APP_PROXY_HOST}${process.env.REACT_APP_BASE_URL}`)
                  wi?.window.document.write(html)
                  if(wi)  {
                  try {
                    var d = wi.document.querySelector('body>section') as any;
                  d.style.maxWidth = '1100px'
                  d.style.margin = 'auto'
                  wi.focus();
                  } catch(e) {

                  }
                 }
                  // window.open + focus
                  //n("/api/itinerary/preview?id=", "Preview Quotation",'height=900,width=1050')?.focus();
                })
              }}>Preview</a>} />
              <Table.Column title="Qutotaions" render={(el: any) =>
              
              +quotationemailsetnloading === el.id ?  <Spin indicator={<LoadingOutlined spin />} size="small" /> :
              <a  onClick={()=>{
                if(+quotationemailsetnloading === 0) {
                dispatch(itinerarysendQuotationToEmail(el.id)).then(()=>{popMessage({type:'info', content: 'Email sent'}); (datatableref.current as any).refresh()}            
       ).catch((e:any)=>console.error(e))
      }

      }} >Send Email Again</a>} />
        <Table.Column title="New Quotation" render={(el: any) => <a  onClick={()=> {
            dispatch(resetquota(''));
            dispatch(createDraftQuotationFromData(el));
            nav('/home/itinerary/quotation')
          }} > Copy to New Qutoation</a> } />
        </DataTable> 
    </Drawer> 
     : null}  
        <DataTable
            title="Customer Quotations" 
            key="id"  
            extra={<>
            {/* <Input placeholder='Filter by email'/>  */}
            <Button type='primary' onClick={()=> nav('/home/itinerary/quotation')}>New Qutotaion for New Customer</Button> </>}
            componentuniquekey="customerlist" 
            lazyPagination={true}
            buildApiUrl={(pageNumber, pagesize)=>`itinerary/fetchCustomer?pageSize=${pagesize}&pageNo=${pageNumber}`} >
              <Table.Column dataIndex="name" title="Customer Name"/>
              <Table.Column dataIndex="email" title="Email"/>
              <Table.Column dataIndex="mobile" title="Telephone"/>
              <Table.Column dataIndex="whatsapp" title="Whatsapp"/>
              <Table.Column dataIndex="address" title="Address"/>
              <Table.Column dataIndex="quotation_count" title="Quotation Count"/>
              <Table.Column title="Last Qutation Date" render={(el: any) => formatDateInTimeZone(el.last_quotation_generated_by?.split('|')[2]) } />
              <Table.Column title="Last Qutation Generated By" render={(el: any) => el.last_quotation_generated_by?.split('|')[0] } />
              <Table.Column title="Qutotaion List" render={(el: any) => <a  onClick={()=>setuiState({...uistate, email: el.email,  showQutotaions: el.id})} >View Qutations</a>} />
              <Table.Column title="New Quotation" render={(el: any) => <a  onClick={()=> nav('/home/itinerary/quotation?id='+el.email )} >New Qutation</a>} />
             </DataTable> 
        </>);
}
