
import React, { useEffect, useRef, useState } from 'react';
import { Card, DatePicker, Modal, Space, Descriptions, Table, Rate, Checkbox, Divider, Row, Col, InputNumber } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Select, TimePicker,  } from 'antd';

import {
    CloseOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs'
import { TableDragableRow } from '../DrageRowTable';
import { useDispatch, useSelector } from 'react-redux';
import { updateDraftQuotation, updateDraftQuotationUiState } from '../slice-itineraryquotation';
import { useForm } from 'antd/es/form/Form';
import { SearchHotelModal } from './HotelModal';

const boardtypeoption = [
    {label: 'RO', value: 'RO'}, 
    {label: 'BB', value: 'BB'}, 
    {label: 'HB', value: 'HB'}, 
    {label: 'FB', value: 'FB'},
    {label: 'AI', value: 'AI'}
  ]

export const calculateHotelPrice = (rooms:number, extrabed:number, el:any) => {
    var nights = +(el.nights || 0);
    var price = +(el.price || 0);
    var perextrabed = +(el.perextrabed || 0); 
   return +(((rooms * nights * price) + (perextrabed * extrabed * nights)).toFixed(2));
   }


export const QHotelList = ({}:any) => {

    const {quotation, saveQuotationdetailloading, uotationuistate}= useSelector((state:any)=>state.itineraryquotation);
    const uistate = uotationuistate;
    const dispatch = useDispatch();
    const ref = useRef<any>();
    const refin = useRef<any>();
    const [form] = useForm();
    var rooms = +(quotation.quotationInfo.rooms || 0);
    var extrabed = +(quotation.quotationInfo.extrabed || 0);


    const setuiState = (state:any) => {
        dispatch(updateDraftQuotationUiState(state))
      }


      const conditionFieldDiv = (field:string, row: any, component : any, valuefn?:any, eventfire?:any, filedPrefix:string='') => {
        return uistate.editId === (filedPrefix+'-'+row.id +'-'+ field) ? 
        <Form.Item name={row.id +'-'+ field}>
                        {React.cloneElement(component, {ref: refin, className:'editableonhover'})}
                      </Form.Item> 
                      : <div onClick={()=>{
          setuiState({...uistate, editId: (filedPrefix+'-'+row.id +'-'+ field) }); 
          setTimeout(()=> {
            form.setFieldValue((row.id +'-'+ field), valuefn? valuefn(row[field]): row[field]);
            eventfire? eventfire(refin):refin.current.focus() 
            
            },100)}
        } className='editableonhover'>
          {row[field]}
          </div>
      }  

      const updateEditedHotelData = (value:any) => {
        const uniquess = uistate.editId.split('-')
                const id = uniquess[1];
                const field = uniquess[2];
                  const n = quotation.hotels.map((el:any)=>{
                  if(el.id+'' === id){
                      const newv =  {...el, [field]:  value}
                      if((field === 'checkoutdate' || field === 'checkindate') && newv.checkindate && newv.checkoutdate) { 
                        newv.nights = dayjs(value, 'DD-MM-YYYY').diff(dayjs(el.checkindate, 'DD-MM-YYYY'), 'days')
                      }
                     return newv;
                  } 
                  return {...el};
                });
              dispatch(updateDraftQuotation({...quotation, hotels: n}))
                setuiState({...uistate, editId: "0" });
      }

return <><br/>
      {uistate.searchHotelModal? <SearchHotelModal open={uistate.searchHotelModal} onCancel={()=>setuiState({...uistate, searchHotelModal: false })}/> : null }
       <Card title="Hotel Info:" extra={
       <Space wrap>Hotel Include <Checkbox checked={uistate.hotelenable} onChange={e=>setuiState({...uistate, hotelenable: !uistate.hotelenable})}/>
       <Divider dashed type='vertical' />
       <Button disabled={!uistate.hotelenable} onClick={e=>setuiState({...uistate, searchHotelModal: true })}>Add Hotel</Button></Space>}>
       
       {
          uistate.hotelenable ?  <>
          <Form name='hotelform' 
              onBlur={(e)=>{

                const filename =  e.target.getAttribute('id')
                if(filename && (uistate.editId.endsWith('price') || uistate.editId.endsWith('perextrabed'))) {
                  const uniquess = uistate.editId.split('-')
                  const id = uniquess[1];
                  const field = uniquess[2];
                  const n = quotation.hotels.map((el:any)=>{
                    if(el.id+'' === id){
                      return  {...el, [field]:  e.target.value}
                    } 
                    return {...el};
                  });
                  dispatch(updateDraftQuotation({...quotation, hotels: n}))
                  setuiState({...uistate, editId: "0" });
                }
              }}
              onValuesChange={(value)=>{
                const uniquess = uistate.editId.split('-')
                const id = uniquess[1];
                const field = uniquess[2];
                const key = id+'-'+field;
                console.log(key)
                if(uistate.editId.endsWith('date')) {
                  updateEditedHotelData(value[key].format('DD-MM-YYYY'))
                } else if(uistate.editId.endsWith('boardtype')) {
                  updateEditedHotelData(value[key])
                }
              }}
        
        >       
       <TableDragableRow ref={ref} data={quotation.hotels} 
       onDragFinish={(newdaa:any)=>dispatch(updateDraftQuotation({...quotation, hotels: newdaa}))}
       rowKey={'id'} >
                <Table.Column dataIndex="id" title="Id"/>
                <Table.Column dataIndex="name" title="Hotel Name"/>
                <Table.Column dataIndex="rating" title="Rating"/>
                <Table.Column dataIndex="price" title="Cost per day per room"
                render={(a, e:any)=>conditionFieldDiv('price',  e, <Input />, undefined, undefined, 'hotel') }
                 />
                <Table.Column title="Rooms" render={(el:any)=>`${quotation.quotationInfo.rooms} rooms`}/>
                <Table.Column title="CheckIn Date"
              render={(a, e:any)=>conditionFieldDiv('checkindate',  e, <DatePicker   minDate={dayjs(quotation.quotationInfo.from, 'DD-MM-YYYY')} maxDate={dayjs(quotation.quotationInfo.to, 'DD-MM-YYYY')}  allowClear={false} format={'DD-MM-YYYY'}/>, (v:any)=>v?dayjs(v, 'DD-MM-YYYY'): dayjs(), (eventfire:any)=>eventfire.current.nativeElement.click()) } />
               <Table.Column title="CheckOut Date"
              render={(a, e:any)=>conditionFieldDiv('checkoutdate',  e, <DatePicker   minDate={e.checkindate ? dayjs(e.checkindate, 'DD-MM-YYYY').add(1, 'day') : dayjs(quotation.quotationInfo.from, 'DD-MM-YYYY')} maxDate={dayjs(quotation.quotationInfo.to, 'DD-MM-YYYY')}  allowClear={false} format={'DD-MM-YYYY'}/>, (v:any)=>v?dayjs(v, 'DD-MM-YYYY'): dayjs(), (eventfire:any)=>eventfire.current.nativeElement.click()) } />
                <Table.Column title="Extra Beds" render={(el:any)=>`${quotation.quotationInfo.extrabed}`}/>
                {!uistate.isFlatPrice ? 
                <Table.Column title="Extra Bed/Day Cost" 
                render={(a, e:any)=>conditionFieldDiv('perextrabed',  e, <Input />, (v:any)=>v, (eventfire:any)=>{}, 'hotel') }
                />:null}
                <Table.Column title="Duration" render={(el:any)=>`${el.nights} night`}/>
                <Table.Column title="Board Type" render={(a, e:any)=>conditionFieldDiv('boardtype',  e, <Select options={boardtypeoption}/>,  undefined, undefined, 'hotel') } />
                {!uistate.isFlatPrice ? 
                <Table.Column title="Total Cost" 
                render={(el:any)=>`${calculateHotelPrice(rooms,extrabed, el)} `}
                
                /> :null}
                <Table.Column title="Action" render={(el:any)=><Button onClick={()=>{
                    const quotationv = {...quotation}
                    quotationv.hotels = quotationv.hotels.filter((e:any)=>e.id !== el.id)
                    dispatch(updateDraftQuotation(quotationv));

                }}><CloseOutlined /></Button>} />
                </TableDragableRow>
                </Form></>
                : null
            }
       </Card>
       </>
}