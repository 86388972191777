import React from 'react';

export const CreateBooking = () => {
    return (
        <div>
            <h1>Hello, World!</h1>
        </div>
    );
}

export default CreateBooking;