import React, { useEffect, useState } from 'react';
import { Alert, Col, DatePicker, Divider, InputNumber, Modal,Drawer, Popconfirm, Popover, Radio, Row, Select, Space, Switch, Table, Tag, message} from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Typography, Tooltip } from 'antd';

import {
  CheckOutlined,
  SearchOutlined,
  InfoCircleOutlined,
  SettingOutlined,
  ClearOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { clearConfirmPopup, downloadExcel, downloadSampleInventoryFile, downloadTicketRequest, filterInvetoryResult, getDownloadCountByInvoice, productWiseCountReport, setInvenotryProductPopupStates, setInventoryfilterData } from './slice-ticketInventory';
import moment from 'moment';
import { MasterSelect } from '../common/MasterSelect';
import ColumnGroup from 'antd/es/table/ColumnGroup';
import Column from 'antd/es/table/Column';
import { getMasterTypeSelectorValue } from '../common/slice-master';
import { FileuploadCommon } from '../common/FileUpload';
import { Roles } from '../common/Role';
import { PreAuthz } from '../security/Authz';
import { TicketListForProduct } from './TicketListForProductwise';
import { ModalDownladTickets } from './TIPDownloadModal';
import { ModalUploadTickets } from './TIPUploadModal';
import { formatDateInTimeZone } from '../utils/CommonFn';
export const COLOR_CODE_VALUE =  {
MIN_TICKET_AVAIL_VALUE: 100,
MAX_TICKET_AVAIL_VALUE: 1000, 
}

const TicketInventoryFilter = ({}) => {
  const [state, setState] = useState();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { data, createloading, loading } = useSelector((state: any) => state.company);
  
  const onFormLayoutChange = (values: any) => {
    var da = {expiry: values.expirebefore, date:  values.date, ticketnature: values.ticketnature, pnr: values.pnr}
    if(da.expiry) {
      da.expiry = da.expiry.toISOString()
    }
    if(da.date) {
      var tzoffset = (new Date()).getTimezoneOffset() * 60000;
      da.date = new Date(da.date.toDate().getTime() - tzoffset).toISOString();
    }
     if(da.ticketnature === 'nvp' && da.date == undefined && da.pnr === undefined) {
      //  message.error('Select ticket date time or pnr');
      //  return;
      }
      dispatch(setInventoryfilterData(values));
      dispatch(productWiseCountReport(da));
  };

  useEffect(() => {
    dispatch(getMasterTypeSelectorValue('Trip Ticket Inventory'));
    dispatch(productWiseCountReport({ticketnature: 'vp'}));
    dispatch(filterInvetoryResult(''))
  }, [])

  return (
    <Form
      name="tiproductfilterform"
      layout={'inline'}
      onValuesChange={e=>{
        if(e.ticketnature) {
          onFormLayoutChange(form.getFieldsValue())
        }
        setState(e)
      }}
      onFinish={onFormLayoutChange}
      form={form}
      initialValues={{ ticketnature: 'vp', type: 'vp' }}
      className="login-form"
      style={{ maxWidth: 'none', padding: '5px' }}

    >     
    <Form.Item
        name="ticketnature"
        label="Select Ticket Nature"
      >
       <Select placeholder="Select Ticket Nature">
              <Select.Option  value={'vp'}>Open Date Ticket</Select.Option>
              <Select.Option  value={'nvp'}> Date Time Tickets (Voucher Ticket)</Select.Option>
            </Select>
        </Form.Item> 
      {form.getFieldValue('ticketnature') === 'nvp'? <>
      <Form.Item name="date" label="Date">
        <DatePicker placeholder='Select Date' />
      </Form.Item>
      <Form.Item
        name="pnr"
        label="PNR"
      >
        <Input placeholder='PNR' />
      </Form.Item>
      </>:null}
      <Form.Item name="expirebefore" label="Expire Before">
        <DatePicker placeholder='Select Expire Before Date'/>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" 
          className="login-form-button" loading={createloading}>
            <SearchOutlined />
          Search
        </Button>
      </Form.Item>
      {/* <Form.Item >
        <Button  ><ClearOutlined /> Clear Filter</Button>
      </Form.Item> */}
    </Form>
  );
};


export const TicketInventoryProduct = () => {
  const dispatch = useDispatch();
  
  let mastermasterSelectValues  = useSelector((state: any) => state.master.masterSelectValues);
  let product = mastermasterSelectValues['Trip Ticket Inventory']
  let { data,  filterData, loading, filterText, invenotryProductPopupStates } = useSelector((state: any) => state.ticketInventory)
  const productId = product?.filter((e:any)=> 
    (filterData.ticketnature === 'nvp' && e.extra?.type && e.extra?.type.toUpperCase() === 'DATETIME') 
   || (filterData.ticketnature === 'vp' && (e.extra?.type === undefined ||  e.extra?.type.toUpperCase() !== 'DATETIME')) 
  ).map((e:any)=>e.id) || [];
  data = filterText && filterText.length > 0 ? data.filter((e: any) => e.product_name.toUpperCase().startsWith(filterText.toUpperCase())): data; 
data = data.filter((e:any)=> productId.indexOf(e.id)>=0)



return  (
       <>
    {invenotryProductPopupStates.download ? <ModalDownladTickets open={invenotryProductPopupStates.download} onClose={() => dispatch(setInvenotryProductPopupStates({ download: undefined })) }/>   : null}
    {invenotryProductPopupStates.upload ? <ModalUploadTickets open={invenotryProductPopupStates.upload} onClose={() => dispatch(setInvenotryProductPopupStates({ upload: undefined }))} /> : null}  
    {invenotryProductPopupStates.statement ? <Drawer 
    placement={'right'}
    open={invenotryProductPopupStates.statement} 
    onClose={() => dispatch(setInvenotryProductPopupStates({ statement: undefined }))} 
    width={'calc(100% - 100px)'}
    title={
     <>
     {"Tickets for: "+invenotryProductPopupStates?.statement?.product_name}
     <Button style={{float: 'right', marginRight: 20}}  onClick={()=>{}}><ReloadOutlined /> Refresh </Button>
            
     </> 
    
    
    }
    
    // footer={(_, { OkBtn, CancelBtn }) => (
    //   <>
    //     <CancelBtn />
    //   </>
    // )}
    destroyOnClose>
        <TicketListForProduct productId={invenotryProductPopupStates?.statement.id} 
        date={filterData.date} pnr={filterData.pnr}
        expiry={filterData.date} />
    </Drawer> 
     : null}  
    
      <Row> <Col span={20}> <TicketInventoryFilter /></Col>
      <Col span={4}>
        <Row justify={"end"} align={'middle'}>
          <Col span={1} >
            <br/>
            {/* <SettingOutlined style={{fontSize: '18px'}}/> */}
          </Col>
        </Row>
      </Col>
      
      </Row>
      <div className='dataTableMobileView ticketTable'>
      <Table        
      dataSource={data}
      pagination={false}
      loading={loading}
      key={'id'}
      bordered
         
      summary={(pageData) => {
        let aadultAvailableCount = 0;
        let achildAvailableCount = 0;

        pageData.forEach(({ total_avail_adult, total_avail_code_adult, total_avail_code_child, total_avail_child }) => {
          aadultAvailableCount += total_avail_adult + total_avail_code_adult;
          achildAvailableCount += total_avail_child + total_avail_code_child;
        });

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total Adult Available Count</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <Typography.Text type="danger">{aadultAvailableCount}</Typography.Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total Child Available Count</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <Typography.Text type="danger">{achildAvailableCount}</Typography.Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
      >
        <Column title={
          <>
          Product Name
          <Input placeholder='Search Product' onChange={(e:any) => dispatch(filterInvetoryResult(e.target.value))} />
          </>
        }  dataIndex="product_name" />
        <ColumnGroup title="Upload Pdf Count" >
        <Column title="Adult" dataIndex="total_adult"  />
        <Column title="Child" dataIndex="total_child"  />
        </ColumnGroup>
        <ColumnGroup title="Available Pdf Count">
        <Column title="Adult" render={(_: any, record: any) => (
          <Typography.Text type={record.total_avail_adult < COLOR_CODE_VALUE.MIN_TICKET_AVAIL_VALUE ? 'danger' : (record.total_avail_adult > COLOR_CODE_VALUE.MAX_TICKET_AVAIL_VALUE ? 'success' : undefined)}>{record.total_avail_adult}</Typography.Text>
        )} />
        <Column title="Child" render={(_: any, record: any) => (
          <Typography.Text type={record.total_avail_child < COLOR_CODE_VALUE.MIN_TICKET_AVAIL_VALUE ? 'danger' : (record.total_avail_child > COLOR_CODE_VALUE.MAX_TICKET_AVAIL_VALUE ? 'success' : undefined)}>{record.total_avail_child}</ Typography.Text>
        )} />
        </ColumnGroup>
      <ColumnGroup title="Upload Media Code Count" >
        <Column title="Adult" dataIndex="total_code_adult"  />
        <Column title="Child" dataIndex="total_code_child" />
      </ColumnGroup>
      <ColumnGroup title="Available Media Code Count">
        <Column title="Adult" render={(_: any, record: any) => (
          <Typography.Text type={record.total_avail_code_adult < COLOR_CODE_VALUE.MIN_TICKET_AVAIL_VALUE ? 'danger' : (record.total_avail_code_adult > COLOR_CODE_VALUE.MAX_TICKET_AVAIL_VALUE ? 'success' : undefined)}>{record.total_avail_code_adult}</Typography.Text>
        )} />
        <Column title="Child" render={(_: any, record: any) => (
          <Typography.Text type={record.total_avail_code_child < COLOR_CODE_VALUE.MIN_TICKET_AVAIL_VALUE ? 'danger' : (record.total_avail_code_child > COLOR_CODE_VALUE.MAX_TICKET_AVAIL_VALUE ? 'success' : undefined)}>{record.total_avail_code_child}</ Typography.Text>
        )} />
      </ColumnGroup>
      <Column title="Last Upload" render={(_: any, record: any)=>{
        if (record.lastu === undefined || record.lastu === null || record.lastu.length === 0) {
          return null;
        }
        return <>
          <Tooltip placement="topLeft" title={<>
            Date:  {formatDateInTimeZone(record.lastUploadDate)} <br />
            By: {record.lastUploadBy}
          </>}>
            <InfoCircleOutlined />
          </Tooltip>
          
        </>
      }}  />
      <Column title="Last Download" render={(_: any, record: any) => {
        if (record.lastd === undefined || record.lastd === null || record.lastd.length === 0) {
          return null;
        }
        return <>
          <Tooltip placement="topLeft" title={<>
            Date:  {formatDateInTimeZone(record.lastDownloadDate)} <br />
            By: {record.lastDownloadBy}
          </>}>
            <InfoCircleOutlined />
          </Tooltip>

        </>
      }} />
        <Column
          title="Action"
          key="action"
          render={(_: any, record: any) => (
            <div className='actionbuttoncontainer'>
              
              <PreAuthz allowedRoles='ticketmanager,superadmin' hideResult={true} >
                <a onClick={() => dispatch(setInvenotryProductPopupStates({ upload: record }))} > Upload</a></PreAuthz>
             
              {filterData.ticketnature === 'vp'? <><a onClick={() => {
                
                if (filterData.ticketnature === 'nvp' && (filterData.date === undefined && filterData.pnr === undefined)) {
                  message.error('Search/Filter with pnr or date time for Time/Rage tickets before download'); return;
                }
                dispatch(setInvenotryProductPopupStates({ download: record }))
              
              }} className={record.totalaval>0?'': 'disabled'}>Download</a>  </>: null}

              {filterData.ticketnature === 'nvp' ? <><a className={record.totalaval > 0 ? '' : 'disabled'} onClick={() => dispatch(setInvenotryProductPopupStates({ statement: record }))}>View Details</a> </>: null}
              <PreAuthz allowedRoles='ticketmanager,superadmin' hideResult={true} >
                <a
                  onClick={() => { dispatch(downloadExcel(record)) }}>
                  Download Details
                </a>
              </PreAuthz>
            </div>
          )}
        />
        </Table>
      </div>
  </>);
}





