import React, { useEffect, useRef, useState } from 'react';
import { Card, DatePicker, Modal, Space, Descriptions, Table, Rate, Checkbox, Divider, Row, Col, InputNumber } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Select, TimePicker,  } from 'antd';

import {
    ReloadOutlined,
    SearchOutlined,
    
    CloseOutlined,
    DeleteOutlined,
    ClearOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { itineraryQuotationProductSearch, addProductToQuotation, itineraryFetchCustomerByEmail, updateDraftQuotation, itineraryFetchHotelByName, addHotelToQuotation, itinerarySaveHotel, itinerarySaveQuotationDetail, itinerarySaveCustomerDetail, updateDraftQuotationUiState, resetquota, itineraryDeleteHotel, itineraryDeleteProduct, saveProductOfIntenotryproduct }
 from '../slice-itineraryquotation';
import { key } from 'localforage';
import { useForm } from 'antd/es/form/Form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { stat } from 'fs';





export const QVisa = () => {
    
    const {quotation, saveQuotationdetailloading, uotationuistate}= useSelector((state:any)=>state.itineraryquotation);
    
    
    const uistate = uotationuistate;
    const visainfo = quotation.visainfo || {}
    console.log(visainfo)
    const dispatch = useDispatch();
    const ref = useRef<any>();
    const refin = useRef<any>();
    const [form] = useForm();


    const setuiState = (state:any) => {
        dispatch(updateDraftQuotationUiState(state))
      }


      const conditionFieldDiv = (field:string, row: any, component : any, valuefn?:any, eventfire?:any, filedPrefix:string='') => {
        row = row || {}
        return uistate.editId === (filedPrefix+'-'+ field) ? 
        <Form.Item name={field}>
                        {React.cloneElement(component, {ref: refin, className:'editableonhover'})}
                      </Form.Item> 
                      : <div onClick={()=>{
          setuiState({...uistate, editId: (filedPrefix+'-'+ field) }); 
          setTimeout(()=> {
            form.setFieldValue((field), valuefn? valuefn(row[field]): row[field]);
            eventfire? eventfire(refin):refin.current.focus() 
            
            },100)}
        } className='editableonhover'>
          {row[field]}
          </div>
      }  

      const updateEditedVisaData = (value:any) => {
        const uniquess = uistate.editId.split('-')
                const prefix = uniquess[0];
                const field = uniquess[1];
                console.log(value, field)
              dispatch(updateDraftQuotation({...quotation, visainfo: {...visainfo, [field]:  value}}))
              setuiState({...uistate, editId: "0" });
      }

    return <>
    <Card title="Visa Info" className='visadetailinfo' extra={<>Visa Inlcude <Checkbox checked={uistate.visaenable} onChange={e=>setuiState({...uistate, visaenable: !uistate.visaenable})}/></>}>
         
         <Form name='productform' form={form}
                onBlur={(e)=>{
  
                  const filename =  e.target.getAttribute('id')
                  if(filename && !uistate.editId.endsWith('date') && !uistate.editId.endsWith('time') && !uistate.editId.endsWith('transferType')) {
                    updateEditedVisaData(e.target.value)
                  }
                }}
          
          >
            {uistate.visaenable?  <>
            <Descriptions column={4} >
              <Descriptions.Item>
              { conditionFieldDiv('visaadultname',  visainfo, <Input />, undefined, undefined, 'visa') }
              </Descriptions.Item>
              <Descriptions.Item label="Visa Adult Count">
                { conditionFieldDiv('visaadultcount',  visainfo, <InputNumber />, undefined, undefined, 'visa') }
                </Descriptions.Item>
                {!uistate.isFlatPrice ? <>
                <Descriptions.Item label="Price">
                { conditionFieldDiv('visaadultprice',  visainfo, <InputNumber />, undefined, undefined, 'visa') }
                  </Descriptions.Item>
                  <Descriptions.Item label="total">
                { (+visainfo.visaadultprice * +visainfo.visaadultcount).toFixed(2) }
                  </Descriptions.Item>
                  </> : ''}
              </Descriptions>
              
              <Descriptions column={4}>
  
              <Descriptions.Item>
              { conditionFieldDiv('visachildname',  visainfo, <Input />, undefined, undefined, 'visa') }
              </Descriptions.Item>
              <Descriptions.Item label="Visa Child Count">
                { conditionFieldDiv('visachildcount',  visainfo, <InputNumber />, undefined, undefined, 'visa') }
                </Descriptions.Item>
                {!uistate.isFlatPrice ? <>
                <Descriptions.Item label="Price">
                { conditionFieldDiv('visachildprice',  visainfo, <InputNumber />, undefined, undefined, 'visa') }
                  </Descriptions.Item>
                  <Descriptions.Item label="total">
                { (+visainfo.visachildcount * +visainfo.visachildprice).toFixed(2) }
                  </Descriptions.Item>
                  </> : ''}
              </Descriptions> </>
              : null}
                </Form>
         </Card>
    </>
}