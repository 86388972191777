import React, { ReactNode, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Card, Dropdown, Row, Select, Space, Drawer, Table, Popover, Popconfirm } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Radio } from 'antd';
import {
    ReloadOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { datatablelistApiFunction } from './slice-datatable';
import { ColumnsType } from 'antd/es/table';

type UrlBuilder = (pageNo: any, pageSize: any) => void

type onPageCallback = () => void

export const DataTable = forwardRef(({ datatransform, extra, children, key="id", title, buildApiUrl, 
    defaultPageSize=10, componentuniquekey, columns, lazyPagination=false, onPaginationChange,
    onRefresh,
     components, rowClassName, bordered, selectedRows, onRowSlection, checkboxProps}: 
    {datatransform?: any, columns?: ColumnsType<any>, children?: any, extra?: ReactNode, 
        title: any, key: string,
        defaultPageSize?: number,
        onPaginationChange?: onPageCallback,
        components?: any,
        bordered?: boolean,
        rowClassName?: string | any,
        selectedRows?: string [],
        onRowSlection?: any,
        checkboxProps?: any,
        onRefresh?: any,
    buildApiUrl: UrlBuilder, lazyPagination?: boolean, componentuniquekey: string}, ref) => {
    const datatabledata = useSelector((state: any) => state.datatable);
    const {data, loading}  = (datatabledata ||{}) [componentuniquekey] || {};
    const {content, totalElements} = data || { content: [], totalElements: 0}
    
    const [page, setPage] = useState({pageNo: 1, pageSize: defaultPageSize, total: 0});


    const refreshPage = () => {
        dispatch(datatablelistApiFunction({componentuniquekey,url: buildApiUrl(page.pageNo, page.pageSize)}))
    }
    const dispatch = useDispatch()
    useEffect(() => {
        refreshPage();
    }, [])
    
    const onPageChange = (pageNo:number) => {
        setPage({...page,pageNo: pageNo})
        if(lazyPagination) {
            dispatch(datatablelistApiFunction({componentuniquekey,url: buildApiUrl(pageNo, page.pageSize)}));
        }
        if(onPaginationChange) {
            onPaginationChange();
        }
    }

    const onPageSizeChange = (pageSize:number) => {
        setPage({...page,pageSize: pageSize, pageNo: 1})
        if(lazyPagination) {
            dispatch(datatablelistApiFunction({componentuniquekey,url: buildApiUrl(1, pageSize)}));
        }
        if(onPaginationChange) {
            onPaginationChange();
        }
    }

    useImperativeHandle(ref, () => ({
        refresh: ()=>{refreshPage()
        }
      }));

    const rowSelection = {
        type: 'checkbox',
        selectedRowKeys: selectedRows,
        onSelect: (selectedRowKeys: any) => {
          let ss = [];
          if(selectedRows && selectedRows.indexOf(selectedRowKeys.id)<0) {
            ss = [...selectedRows, selectedRowKeys.id]
           } else {
              const n = selectedRows? [...selectedRows] : [];
                  const index = n.indexOf(selectedRowKeys.id);
                if (index > -1) {
                   n.splice(index, 1); 
                 }
              ss = n;
            }
            onRowSlection(ss);
        },
        onSelectNone: () => {
            onRowSlection([])
        },
        onSelectAll: (selectedRowKeys: any, data:any) => {
          if(selectedRowKeys) {
            onRowSlection(data.map((e:any)=>e[key]))
          } else {
            onRowSlection([])
          }
        },
        getCheckboxProps: (record: any) => ({
          name: record.id,
          key: record.id,
          ...(checkboxProps ? (checkboxProps(record) || {}) : {})
        }),
      } as any;

    return (<>

    <div className='userContentBox'> 
            <Card title={title} loading={loading} extra={<Space>
                {extra}
                <Button loading={loading} onClick={() => refreshPage()}>
                    {loading ? null : <ReloadOutlined />}
                    Refresh </Button>
                    <Select
                    defaultValue={10}
                    style={{ width: 120 }}
                    onChange={onPageSizeChange}
                    disabled={loading}
                    options={[
                        { value: 10, label: '10 / page' },
                        { value: 20, label: '20 / page' },
                        { value: 50, label: '50 / page' },
                        { value: 100, label: '100 / page' },
                    ]}
                    />
            </Space>} >
                <div className='dataTableMobileView'>
                    <Table
                        rowSelection={ onRowSlection && selectedRows ? rowSelection : false }
                        dataSource={datatransform ? datatransform((lazyPagination ? content : data ) || []) : ((lazyPagination ? content : data ) || [])}
                        rowKey={key}
                        components={components}
                        rowClassName={rowClassName}
                        bordered={bordered}
                        pagination={{
                            current: page.pageNo,
                            pageSize: page.pageSize, total:  lazyPagination? totalElements : (data || []).length, 
                        showTotal: (total, range) => {
                            return `${range[0]}-${range[1]} of ${total} items`
                        },
                        pageSizeOptions: [],
                        showSizeChanger: false,
                        onChange:onPageChange}}
                        columns={columns}
                    >
                        {children}
                    </Table>
                </div>
            </Card>
        </div>
    </>);
})







