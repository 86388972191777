import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { AsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ThunkHttpClient } from '../utils/httpThunkClient'
import { stat } from 'fs'
import { popMessage } from '../../App'
import moment from 'moment'
import { act } from 'react-dom/test-utils'
import dayjs from 'dayjs'
import { getProductTotal } from './Quotation'
import { calculateHotelPrice } from './createQuotation/QHotelList'


// First, create the thunk
// Define a type for the slice state
export const itineraryQuotationProductSearch = ThunkHttpClient.get('itineraryquotation/search',
    (data) => ("itinerary/productList?pageSize=1&pageNo=1") )

    

export const itineraryQuotationProductSync = ThunkHttpClient.get('itineraryquotation/sysncsearch',
    (data) => ("itinerary/syncItineraryProducts") )

export const itineraryFetchCustomerByEmail = ThunkHttpClient.get('itineraryquotation/itineraryFetchCustomerByEmail',
    (data) => ("itinerary/searchCustomer?email="+data.email) )

export const itineraryFetchHotelByName = ThunkHttpClient.get('itineraryquotation/itineraryFetchHotelByName',
    (data) => ("itinerary/searchHotel?name="+data))

export const itineraryPreview = ThunkHttpClient.get('itineraryquotation/itineraryFetchHotelByName',
    (data) => ("itinerary/previewQuotation?quotation_id="+data))

export const itinerarySaveHotel = ThunkHttpClient.post('itineraryquotation/itinerarySaveHotel',
    (data) => ({ url: "itinerary/saveHotel", body:  data}))

    export const itinerarySaveQuotationDetail = ThunkHttpClient.post('itineraryquotation/saveQuotation',
    ({customerid, tobesend, quotation, uistate}) => {
        
        const body =  transformToBackend(quotation, uistate)
        console.log(body, JSON.parse(body.quotationDetails))
  //      if("1" === "1") 
 //           throw "ex";

        return { url: "itinerary/saveQuotation?customerId="+customerid+"&sendToEmail="+tobesend, body:  body} })

    export const itinerarySaveCustomerDetail = ThunkHttpClient.post('itineraryquotation/savitinerarySaveCustomerDetaileQuotation',
    (body) => { return { url: "itinerary/saveCustomer", body:  body} })

    export const itinerarysendQuotationToEmail = ThunkHttpClient.get('itineraryquotation/savitinerarysendQuotationToEmaileQuotation',
    (body) => "itinerary/sendQuotationToEmail?quotation_id="+body)

    export const itineraryDeleteProduct = ThunkHttpClient.delete('itineraryquotation/savitinerarysendQuotationToProductDelete',
    (id) => "itinerary/deleteHotel?id="+id)

    export const itineraryDeleteHotel = ThunkHttpClient.delete('itineraryquotation/savitinerarysendQuotationToHotelDeete',
    (id) => "itinerary/deleteProduct?id="+id)

    export const saveProductOfIntenotryproduct = ThunkHttpClient.post('itineraryquotation/saveProductOfIntenotryproduct',
    (data) => { return { url: "itinerary/saveProduct", body:  {productName: data.productname, price: data.cost}} })  
    


const transformToBackend = (quotation:any, uistate: any) => {

    return {
        adultCount: quotation.quotationInfo.adult ,
        childCount: quotation.quotationInfo.child,
        fromDate: dayjs(quotation.quotationInfo.from, "DD-MM-YYYY").toISOString() ,
        toDate: dayjs(quotation.quotationInfo.to,"DD-MM-YYYY").toISOString() ,
        isVisa: uistate.visaenable,
        isTour: uistate.tourenable,
        isHotel: uistate.hotelenable,
        isFightEnable: uistate.flightenable,
        quotationDetails: JSON.stringify({
          flights: (quotation.flights || []).map((el:any)=>({...el, totalcost: (+el.child + +el.adult)*(+el.costperticket)})),
          product: quotation.products.map((e:any)=>({
          ...e, total: getProductTotal(e, +quotation.quotationInfo.adult + +quotation.quotationInfo.child), adult: quotation.quotationInfo.adult, child: quotation.quotationInfo.child, 
        })), hotels: quotation.hotels.map((el:any)=>({...el, totalcost: +el.price})), 
        visainfo: quotation.visainfo,
        uistate: uistate,
        quotationInfo: {
          adultCount: quotation.quotationInfo.adult,
          childCount: quotation.quotationInfo.child,
          fromDate: dayjs(quotation.quotationInfo.from, "DD-MM-YYYY").toISOString() ,
          toDate: dayjs(quotation.quotationInfo.to,"DD-MM-YYYY").toISOString()
        }})
      }
}    
    

// Define the initial state using that type
const initialState: any = {
    searchloading: false,
    searchdata: [],
    quotationemailsetnloading : 0,
    hotelloading: false,
    hoteldata: [],
    uotationuistate: {serachProductPopup: false, 
        searchHotelModal: false, editId: "", transferType: '', visaprice: 0, 
        visaenable: true,
        tourenable: true, 
        hotelenable: true,
        total: 0,
        discount: 0,
        vat: 0,
        visaname: 'Dubai Tour Visa',  customer: {id: 0}},
    quotation: {     
        customerdetail: {}, 
        quotationInfo: {},
        products: [], 
        hotels: [], 
        visainfo: {}
    }

}

export const itineraryquotation = createSlice({
    name: 'itineraryquotation',
    initialState,
    reducers: {

        resetquota: (state,action) => {
            state.uotationuistate = {serachProductPopup: false, 
                searchHotelModal: false, editId: "", transferType: '', visaprice: 0, 
                visaenable: true,
                tourenable: true, 
                hotelenable: true,
                total: 0,
                discount: 0,
                vat: 0,
                visaname: 'Dubai Tour Visa',  customer: {id: 0}};
            state.quotation = {     
                customerdetail: {}, 
                quotationInfo: {},
                products: [], 
                hotels: [], 
                visa: {}
            }
        },
        addProductToQuotation: (state, action) => {
            state.quotation.products = [...state.quotation.products, ...action.payload]
        },
        addHotelToQuotation : (state, action) => {
            state.quotation.hotels = [...state.quotation.hotels, ...action.payload]
        },
        updateDraftQuotation: (state, action) => {
            state.quotation =  action.payload
        },

        updateDraftQuotationUiState: (state, action) => {
            
            state.uotationuistate =  action.payload
            const quotation = state.quotation
            const uistate = state.uotationuistate

    
            var rooms = +(quotation.quotationInfo.rooms || 0);
            var extrabed = +(quotation.quotationInfo.extrabed || 0);
            let totalseat = +quotation.quotationInfo.adult + +quotation.quotationInfo.child
            let totalhr = uistate.hotelenable ?  quotation.hotels.reduce((lastprice:number, el:any )=>calculateHotelPrice(rooms, extrabed, el) +lastprice, 0) : 0;
            let totalProduct =  +(uistate.tourenable ?   quotation.products.reduce((lastprice:number, e:any )=> getProductTotal(e, totalseat) +lastprice
            , 0) : 0);
            let flightstotal = uistate.flightenable? (quotation.flights || []).reduce((lastprice:number, el:any )=> lastprice + (+el.child + +el.adult)*(+el.costperticket), 0) : 0
            
            
    
            if(uistate.visaenable) {
            let visaadultpricing = (+quotation.visainfo.visaadultcount || 0) * (+quotation.visainfo.visaadultprice  || 0);
            let visachildpricing = (+quotation.visainfo.visachildcount || 0) * (+quotation.visainfo.visachildprice || 0);
            totalProduct += +visaadultpricing + +visachildpricing;
            }
            let calculatedTotal = +totalProduct + +totalhr + +flightstotal;

            uistate.isFlatPrice = (uistate.flattotal && uistate.flattotal>0) || false;

            calculatedTotal =  +calculatedTotal ;
            calculatedTotal = +calculatedTotal.toFixed(2);

            let total = uistate.isFlatPrice ? uistate.flattotal : calculatedTotal;
            
            let totalAfterDiscuount = 0;
            if(uistate.discounttype === 'flatdiscount') {
                totalAfterDiscuount = total -  uistate.discount;
            } else {
                totalAfterDiscuount = total - (total * uistate.discount / 100) as any; 
            }
            totalAfterDiscuount = +totalAfterDiscuount.toFixed(2);


            uistate.totalAfterDiscuount = totalAfterDiscuount
            uistate.calculatedTotal = calculatedTotal
    
        },


        createDraftQuotationFromData: (state, action) => {
            try {
                var q = JSON.parse(action.payload.quotationDetails);
                state.quotation.visa.visaenable = q.visainfo.visaenable;
                state.quotation.visa.visaprice = q.visainfo.visaprice;
                state.quotation.products = q.product;
                state.quotation.hotels = q.hotels;
                state.quotation.flights = q.flights;
                state.quotation.visainfo = q.visainfo;
                state.uotationuistate = q.uistate
                if(state.uotationuistate.customer)
                state.uotationuistate.customer.id = 0;
                state.quotation.quotationInfo = q.quotationInfo;
                if(state.quotation.hotels.length>0) {
                    var h = state.quotation.hotels[0];
                    state.quotation.quotationInfo = {
                        ...q.quotationInfo,
                        rooms: h.rooms,
                        extrabed: h.extrabed, 
                        nights: h.nights
                    }
                }
            } catch(e){
                alert('check the console for error')
                console.error(e)
            }
        }
    },
    extraReducers: (builder) => {
    
        builder.addCase(itineraryQuotationProductSearch.fulfilled, (state, action) => {
            state.searchloading = false;
            state.searchdata = action.payload.data
        })
        builder.addCase(itineraryQuotationProductSearch.pending, (state, action) => {
            state.searchdata=[];
            state.searchloading = true;
        })
        builder.addCase(itineraryQuotationProductSearch.rejected, (state, action) => {
            state.searchloading = false;
        })


        //
        builder.addCase(itineraryQuotationProductSync.fulfilled, (state, action) => {
            state.syncprodctloading = false;
        })
        builder.addCase(itineraryQuotationProductSync.pending, (state, action) => {
            state.searchdata=[];
            state.syncprodctloading = true;
        })
        builder.addCase(itineraryQuotationProductSync.rejected, (state, action) => {
            state.syncprodctloading = false;
        })


        //
        builder.addCase(itineraryFetchCustomerByEmail.fulfilled, (state, action) => {
            state.searchByEmailCustomerLoading = false;
        })
        builder.addCase(itineraryFetchCustomerByEmail.pending, (state, action) => {
            state.searchByEmailCustomerLoading = true;
        })
        builder.addCase(itineraryFetchCustomerByEmail.rejected, (state, action) => {
            state.searchByEmailCustomerLoading = false;
        })

         //
         builder.addCase(itineraryFetchHotelByName.fulfilled, (state, action) => {
            state.hotelloading = false;
            state.hoteldata = action.payload.data;
            
        })
        builder.addCase(itineraryFetchHotelByName.pending, (state, action) => {
            state.hotelloading = true;
        })
        builder.addCase(itineraryFetchHotelByName.rejected, (state, action) => {
            state.hotelloading = false;
        })


         //
         builder.addCase(itinerarySaveHotel.fulfilled, (state, action) => {
            state.savehotelloading = false;
            
        })
        builder.addCase(itinerarySaveHotel.pending, (state, action) => {
            state.savehotelloading = true;
        })
        builder.addCase(itinerarySaveHotel.rejected, (state, action) => {
            state.savehotelloading = false;
        })



        builder.addCase(itinerarySaveQuotationDetail.fulfilled, (state, action) => {
            state.saveQuotationdetailloading = false;
            
        })
        builder.addCase(itinerarySaveQuotationDetail.pending, (state, action) => {
            state.saveQuotationdetailloading = true;
        })
        builder.addCase(itinerarySaveQuotationDetail.rejected, (state, action) => {
            state.saveQuotationdetailloading = false;
        })

        builder.addCase(itinerarysendQuotationToEmail.fulfilled, (state, action) => {
            state.quotationemailsetnloading = 0;     
        })
        builder.addCase(itinerarysendQuotationToEmail.pending, (state, action) => {
            state.quotationemailsetnloading = action.meta.arg;
        })
        builder.addCase(itinerarysendQuotationToEmail.rejected, (state, action) => {
            state.quotationemailsetnloading = 0;
        })

       

        //
        builder.addCase(itineraryDeleteProduct.fulfilled, (state, action) => {
            state.quotaionproductdeleteeloading = 0;
            popMessage({type:'info', content: 'Product deleted successfully'})     
        })
        builder.addCase(itineraryDeleteProduct.pending, (state, action) => {
            state.quotaionproductdeleteeloading = action.meta.arg;
        })
        builder.addCase(itineraryDeleteProduct.rejected, (state, action) => {
            state.quotaionproductdeleteeloading = 0;
            
        })

        //
        builder.addCase(itineraryDeleteHotel.fulfilled, (state, action) => {
            state.quotaionproducthoteldeleteloading = 0;   
            popMessage({type:'info', content: 'Hotel deleted successfully'})   
              
        })
        builder.addCase(itineraryDeleteHotel.pending, (state, action) => {
            state.quotaionproducthoteldeleteloading = action.meta.arg;
        })
        builder.addCase(itineraryDeleteHotel.rejected, (state, action) => {
               
            state.quotaionproducthoteldeleteloading = 0;
        })


        //
        builder.addCase(saveProductOfIntenotryproduct.fulfilled, (state, action) => {
            state.quotaionproductaddeloading = 0;  
           popMessage({type:'info', content: 'Product added successfully'})  
               
        })
        builder.addCase(saveProductOfIntenotryproduct.pending, (state, action) => {
            state.quotaionproductaddeloading = action.meta.arg;
        })
        builder.addCase(saveProductOfIntenotryproduct.rejected, (state, action) => {
            state.quotaionproductaddeloading = 0;
        })



        
    },
})


export const { addProductToQuotation, updateDraftQuotation , updateDraftQuotationUiState, resetquota, addHotelToQuotation, createDraftQuotationFromData } = itineraryquotation.actions
export default itineraryquotation.reducer