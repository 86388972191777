import React, { useEffect, useState } from 'react';
import CardBox from '../common/CardBox';
import { Link } from 'react-router-dom';
import { Button, Card, DatePicker, Form, GetProp, Input, Select, Table, TableProps } from 'antd';
import { UserOutlined, SearchOutlined, ClearOutlined, CloseOutlined } from '@ant-design/icons';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import qs from 'qs';

interface DataType {
    name: {
        first: string;
        last: string;
    };
    gender: string;
    email: string;
    login: {
        uuid: string;
    };
}

interface TableParams {
    pagination?: any;
    sortField?: SorterResult<any>['field'];
    sortOrder?: SorterResult<any>['order'];
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Name',
        dataIndex: 'name',
        sorter: true,
        render: (name) => `${name.first} ${name.last}`,
        width: '20%',
    },
    {
        title: 'Gender',
        dataIndex: 'gender',
        filters: [
            { text: 'Male', value: 'male' },
            { text: 'Female', value: 'female' },
        ],
        width: '20%',
    },
    {
        title: 'Email',
        dataIndex: 'email',
    },
];

const getRandomuserParams = (params: TableParams) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
});

const SearchBooking = () => {
    const [form] = Form.useForm();
    const [clientReady, setClientReady] = useState<boolean>(false);

    // To disable submit button at the beginning.
    useEffect(() => {
        setClientReady(true);
    }, []);

    const onFinish = (values: any) => {
        console.log('Finish:', values);
    };

    return (
        <Form form={form} name="search_booking" layout="inline" onFinish={onFinish}>
            <Form.Item className="filterTextfieldBox"
                label="Search"
                rules={[{ required: true, message: 'Please input your search keywords!' }]}
            >
                <Input suffix={<SearchOutlined />} placeholder="Search" className='searchInputField' />
            </Form.Item>
            <Form.Item className="filterTextfieldBox"
                label="Created From"
                rules={[{ required: true, message: 'Please select created from!' }]}
            >
                <DatePicker />
            </Form.Item>

            <Form.Item className="filterTextfieldBox"
                label="Created To"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <DatePicker />
            </Form.Item>

            <Form.Item className="filterTextfieldBox"
                label="Status"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Select
                    defaultValue="All"
                    options={[
                        { value: 'All', label: 'All' },
                        { value: 'booking_created', label: 'Booking created' },
                    ]}
                />
            </Form.Item>
            <Form.Item className="filterClearBox" shouldUpdate>
                {() => (
                    <Button type="primary" shape="round" icon={<CloseOutlined />} size="middle" />
                )}
            </Form.Item>
        </Form>
    );
};


const BookingData = () => {
    const [data, setData] = useState<DataType[]>();
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const fetchData = () => {
        setLoading(true);
        fetch(`https://randomuser.me/api?${qs.stringify(getRandomuserParams(tableParams))}`)
            .then((res) => res.json())
            .then(({ results }) => {
                setData(results);
                setLoading(false);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: 200,
                        // 200 is mock data, you should read it from server
                        // total: data.totalCount,
                    },
                });
            });
    };

    useEffect(() => {
        fetchData();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sortOrder,
        tableParams?.sortField,
        JSON.stringify(tableParams.filters),
    ]);

    const handleTableChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
            sortField: Array.isArray(sorter) ? undefined : sorter.field,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };

    return (
        <Table
            columns={columns}
            rowKey={(record) => record.login.uuid}
            dataSource={data}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
        />
    );
};

export const Booking = () => {
    return <CardBox title="Booking" extra={
        <Link to={"/home/booking/create"} >+ Create Booking</Link>}>
        <Card>
            <SearchBooking />
        </Card>
        <Card>
            <BookingData />
        </Card>
    </CardBox>
}

export default Booking;